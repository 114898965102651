
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig'
import UtilPopup from "@/components/util/Popup.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import AtomicAlmightyEmployeesubCheck from "@/components/atomic/almighty/employeesub/Check.vue";

@Component({
  components: {
    UtilPopup,
    LContent,
    LBread,
    AtomicAlmightyEmployeesubCheck,
  }
})
export default class Main extends Mixins(mixinConfig) {
  get belong_shiftgroup() {
    return this.m.tmp_objects.belong_shiftgroup;
  }
  set belong_shiftgroup(val) {
    this.m.setTmpObjects({name: 'belong_shiftgroup', val: val});
  }
  get shiftgroup() {
    return this.m.tmp_objects.shiftgroup ?? {};
  }
}
