
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'punch_round';
    this._info = 'info.punch_round';
    this._popup_title = 'punch_round';
    this._popup_content = 'popup.punch_round';
    this._popup_img = 'info/round_info.svg';
  }
}
