
  import { Component, Mixins } from "vue-property-decorator";
  import mixinConfig from "@/mixins/mixinConfig";
  import UtilPopup from "@/components/util/Popup.vue";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import AtomicAlmightyEmployeeCheck from "@/components/atomic/almighty/employee/Check.vue";

  @Component({
    components: {
      UtilPopup,
      LContent,
      LBread,
      AtomicAlmightyEmployeeCheck,
    },
  })
  export default class Main extends Mixins(mixinConfig) {
    get belong_paymentgroup() {
      return this.m.tmp_objects.belong_paymentgroup;
    }
    set belong_paymentgroup(val) {
      this.m.setTmpObjects({ name: "belong_paymentgroup", val });
    }
    get paymentgroup() {
      return this.m.tmp_objects.paymentgroup ?? {};
    }
  }
