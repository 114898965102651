
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigAttendanceRoundPunch from "@/components/config/attendance/round/Punch.vue";
import ConfigAttendanceRoundBreak from "@/components/config/attendance/round/Break.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigAttendanceRoundPunch,
    ConfigAttendanceRoundBreak,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.raw_object});
    }
  }

  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.start_punch_round != this.object.start_punch_round ||
      ((this.object.start_punch_round != 0 && this.object.start_punch_round != 100) && this.raw_object.start_punch_round_type != this.object.start_punch_round_type) ||
      this.raw_object.end_punch_round != this.object.end_punch_round ||
      ((this.object.end_punch_round != 0 && this.object.end_punch_round != 100) && this.raw_object.end_punch_round_type != this.object.end_punch_round_type) ||
      this.raw_object.show_punch_round != this.object.show_punch_round ||
      this.raw_object.start_break_round != this.object.start_break_round ||
      (this.object.start_break_round != 0 && this.raw_object.start_break_round_type != this.object.start_break_round_type) ||
      this.raw_object.end_break_round != this.object.end_break_round ||
      (this.object.end_break_round != 0 && this.raw_object.end_break_round_type != this.object.end_break_round_type) ||
      this.raw_object.show_break_round != this.object.show_break_round
    );
    return this.manager.change_flag;
  }
}
