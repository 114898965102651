
  import { Component, Mixins } from "vue-property-decorator";
  import mixinConfig from "@/mixins/mixinConfig";
  import LProgress from "@/components/l/Progress.vue";
  import ConfigShiftDisp from "@/components/config/shift/Disp.vue";
  import ConfigShiftGroup from "@/components/config/shift/Group.vue";

  @Component({
    components: {
      LProgress,
      ConfigShiftDisp,
      ConfigShiftGroup,
    },
  })
  export default class Main extends Mixins(mixinConfig) {
    created() {
      this.m.initEditManager({ name: "shift_disp" });
    }
  }
