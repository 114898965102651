import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import vuex_table from '@/vuex/vuex_table';

@Component
export default class mixinConfig extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.config_manager;
  }
  get global() {
    return vuex_data.config_global.get(this);
  }
  get base() {
    return vuex_data.config_base.get(this);
  }
  get allowance() {
    return vuex_data.config_allowance.get(this);
  }
  get group() {
    return vuex_data.config_group.get(this);
  }
  get branch() {
    return vuex_data.config_branch.get(this);
  }
  get info() {
    return vuex_data.config_info.get(this);
  }

  get shiftgroup_table() {
    return vuex_table.shiftgroup.get(this);
  }
  get paymentgroup_table() {
    return vuex_table.paymentgroup.get(this);
  }
  get payment_allowance_table() {
    return vuex_table.payment_allowance.get(this);
  }
  get payment_deduction_table() {
    return vuex_table.payment_deduction.get(this);
  }
  get payment_other_allowance_table() {
    return vuex_table.payment_other_allowance.get(this);
  }
  get bonus_allowance_table() {
    return vuex_table.bonus_allowance.get(this);
  }
  get bonus_deduction_table() {
    return vuex_table.bonus_deduction.get(this);
  }
  get bonus_other_allowance_table() {
    return vuex_table.bonus_other_allowance.get(this);
  }
  get employeeinfo_table() {
    return vuex_table.employeeinfo.get(this);
  }

  get menu_options() {
    var options = {};

    if (this.global.config.showable_company) {
      options['shift'] = {name: 'シフト', route: 'shift_disp', pages: ['shift_disp', 'shift_group']};
      options['attendance'] = {name: '勤怠', route: 'attendance_holiday', pages: ['attendance_holiday', 'attendance_round', 'attendance_punch', 'attendance_other']};
      options['payment'] = {name: '給与・賞与', route: 'payment_pay', pages: ['payment_pay', 'payment_rate', 'payment_slip', 'payment_allowance', 'payment_other', 'payment_group']};
      options['system'] = {name: 'システム設定', route: 'system_info', pages: ['system_info', 'system_alert', 'system_order']};
      if (this.s_auth == 2) options['branch_auth'] = {name: '店舗管理者権限', route: 'branch_auth', pages: ['branch_auth']};
      options['option'] = {name: '高度な設定', route: 'option_employee', pages: ['option_employee']};
    } else if (this.global.config.showable_group) {
      options['shift'] = {name: 'シフトグループ', route: 'shift_group', pages: ['shift_group']};
      options['payment'] = {name: '給与グループ', route: 'payment_group', pages: ['payment_group']};
    }
    
    return options;
  }

  get menu_sub_options() {
    var options = {};

    options['shift'] = []
    if (this.global.config.showable_company) {
      options['shift'].push({name: '基本設定', route: 'shift_disp', pages: ['shift_disp']});
    }
    if (this.global.config.showable_group) {
      options['shift'].push({name: 'シフトグループ', route: 'shift_group', pages: ['shift_group']});
    }

    if (this.global.config.showable_company) {
      options['attendance'] = [];
      options['attendance'].push({name: '休日設定', route: 'attendance_holiday', pages: ['attendance_holiday']});
      options['attendance'].push({name: '丸め設定', route: 'attendance_round', pages: ['attendance_round']});
      options['attendance'].push({name: '打刻設定', route: 'attendance_punch', pages: ['attendance_punch']});
      options['attendance'].push({name: 'その他設定', route: 'attendance_other', pages: ['attendance_other']});
    }

    options['payment'] = [];
    if (this.global.config.showable_company) {
      options['payment'].push({name: '支払設定', route: 'payment_pay', pages: ['payment_pay']});
      options['payment'].push({name: '割増賃金設定', route: 'payment_rate', pages: ['payment_rate']});
      options['payment'].push({name: '明細設定', route: 'payment_slip', pages: ['payment_slip']});
      options['payment'].push({name: '手当・控除設定', route: 'payment_allowance', pages: ['payment_allowance']});
      options['payment'].push({name: 'その他設定', route: 'payment_other', pages: ['payment_other']});
    }
    if (this.global.config.showable_group) {
      options['payment'].push({name: '給与グループ', route: 'payment_group', pages: ['payment_group']});
    }

    if (this.global.config.showable_company) {
      options['option'] = []
      options['option'].push({name: 'オプション機能', route: 'option_employee', pages: ['option_employee']});

      if (this.s_auth == 2) {
        options['branch_auth'] = []
        options['branch_auth'].push({name: '店舗管理者権限', route: 'branch_auth', pages: ['branch_auth']});
      }
      
      options['system'] = []
      options['system'].push({name: 'カスタム情報', route: 'system_info', pages: ['system_info']});
      options['system'].push({name: '通知機能', route: 'system_alert', pages: ['system_alert']});
      options['system'].push({name: '申請機能', route: 'system_order', pages: ['system_order']});
    }

    return options;
  }
}
