
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigPaymentSlipShowzero from "@/components/config/payment/slip/Showzero.vue";
import ConfigPaymentSlipRemainpaidhol from "@/components/config/payment/slip/Remainpaidhol.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigPaymentSlipShowzero,
    ConfigPaymentSlipRemainpaidhol,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.raw_object});
    }
  }
  
  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.show_zero != this.object.show_zero ||
      this.raw_object.remain_paid_hol != this.object.remain_paid_hol
    );
    return this.manager.change_flag;
  }
}
