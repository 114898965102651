
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FToggle from "@/components/f/Toggle.vue";
  import dialog from "@/vuex/dialog";

  @Component({
    components: {
      LItem,
      FToggle,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "is_day_over";
      this._info = "info.is_day_over";
      this._popup_title = "is_day_over";
      this._popup_content = "popup.is_day_over";
    }

    changeValue(val) {
      if (val) {
        this.object.is_day_over = 1;
        return;
      }
      dialog.openOptionDialog({
        title: "注意",
        msg: "残業計算を使用しない場合は手動で計算が必須です",
        detail:
          "自動計算を強くお勧めします。<br>「計算しない」にした場合は手動で残業代を計算して支給する必要があります。<br>残業代を支給しない場合は法令違反となります。<br>本当に「計算しない」に変更してよろしいですか？",
        func: this.changeValueForce,
        args: {},
        cancel_func: () => {
          this.object.is_day_over = 1;
        },
        cancel_args: {},
      });
    }
    changeValueForce() {
      dialog.openOptionDialog({
        title: "注意",
        msg: "本当によろしいですか？",
        detail:
          "残業計算を使用しない場合は手動で計算が必須です。<br>自動計算を強くお勧めします。<br>「計算しない」にした場合は手動で残業代を計算して支給する必要があります。<br>残業代を支給しない場合は法令違反となります。<br>本当に「計算しない」に変更してよろしいですか？",
        func: () => {
          this.object.is_day_over = 0;
        },
        args: {},
        cancel_func: () => {
          this.object.is_day_over = 1;
        },
        cancel_args: {},
      });
    }
  }
