
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigPaymentRateLawoverrate from "@/components/config/payment/rate/Lawoverrate.vue";
import ConfigPaymentRatePreoverrate from "@/components/config/payment/rate/Preoverrate.vue";
import ConfigPaymentRateSixtylawoverrate from "@/components/config/payment/rate/Sixtylawoverrate.vue";
import ConfigPaymentRateOutholrate from "@/components/config/payment/rate/Outholrate.vue";
import ConfigPaymentRateLawholrate from "@/components/config/payment/rate/Lawholrate.vue";
import ConfigPaymentRateMidrate from "@/components/config/payment/rate/Midrate.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigPaymentRateLawoverrate,
    ConfigPaymentRatePreoverrate,
    ConfigPaymentRateSixtylawoverrate,
    ConfigPaymentRateOutholrate,
    ConfigPaymentRateLawholrate,
    ConfigPaymentRateMidrate,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.raw_object});
    }
  }

  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.over_rate != this.object.over_rate ||
      this.raw_object.pre_over_rate != this.object.pre_over_rate ||
      this.raw_object.sixty_over_rate != this.object.sixty_over_rate ||
      this.raw_object.out_hol_rate != this.object.out_hol_rate ||
      this.raw_object.law_hol_rate != this.object.law_hol_rate ||
      this.raw_object.mid_rate != this.object.mid_rate
    );
    return this.manager.change_flag;
  }
}
