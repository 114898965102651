
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import LPage from "@/components/l/Page.vue";
import LBread from "@/components/l/Bread.vue";
import LSidemenu from "@/components/l/Sidemenu.vue";
import FSidemenu from "@/components/f/Sidemenu.vue";
import ConfigShiftMain from "@/components/config/shift/Main.vue";
import ConfigAttendanceMain from "@/components/config/attendance/Main.vue";
import ConfigPaymentMain from "@/components/config/payment/Main.vue";
import ConfigOptionMain from "@/components/config/option/Main.vue";
import ConfigBranchauthMain from "@/components/config/branchauth/Main.vue";
import ConfigSystemMain from "@/components/config/system/Main.vue";

@Component({
  components: {
    LProgress,
    LPage,
    LBread,
    LSidemenu,
    FSidemenu,
    ConfigShiftMain,
    ConfigAttendanceMain,
    ConfigPaymentMain,
    ConfigOptionMain,
    ConfigBranchauthMain,
    ConfigSystemMain,
  }
})
export default class Main extends Mixins(mixinConfig) {
}
