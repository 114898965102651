
import { Provide } from '@/library/provide';
import mixinPage from '@/mixins/mixinPage';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import FText from "@/components/f/Text.vue";
import FSelect from "@/components/f/Select.vue";
import FCheck from "@/components/f/Check.vue";
import FButton from "@/components/f/Button.vue";
import FControlEditdelete from "@/components/f/ControlEditdelete.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    FText,
    FSelect,
    FCheck,
    FButton,
    FControlEditdelete,
    FControl,
  }
})
export default class Row extends Mixins(mixinPage) {
  @Prop() bonus_allowance: any;
  @Prop() paymentgroup_hash: any;
  @Prop() individual_unit_hash: any;
  @Prop() length: number;
  @Prop() global_config: any;

  get raw_object() {
    return this.bonus_allowance;
  }
  get tmp_object() {
    return this.manager.object.bonus_allowance;
  }

  @Provide('is_edit')
  get is_edit() {
    return this.raw_object && this.manager.flag == this.raw_object.id;
  }
  @Provide('is_add')
  get is_add() {
    return !this.raw_object && !!this.manager.flag;
  }
  get rowspan() {
    return this.object.init_type >= 2 ? 2 : 1;
  }

  up(id) {
    this.$emit('up', id);
  }
  down(id) {
    this.$emit('down', id);
  }
}
