
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FRadio from "@/components/f/Radio.vue";

@Component({
  components: {
    LItem,
    FRadio,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'before_join';
    this._info = 'info.before_join';
  }
}
