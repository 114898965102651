
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import ConfigOptionEmployeeNickname from "@/components/config/option/employee/Nickname.vue";
  import ConfigOptionEmployeeAuthgensentax from "@/components/config/option/employee/Authgensentax.vue";
  import ConfigOptionEmployeeIsbank2 from "@/components/config/option/employee/Isbank2.vue";
  import ConfigOptionEmployeeBranchhourlywage from "@/components/config/option/employee/Branchhourlywage.vue";
  import ConfigOptionEmployeeBranchcarfare from "@/components/config/option/employee/Branchcarfare.vue";
  import ConfigOptionEmployeeIsincometaxopt from "@/components/config/option/employee/Isincometaxopt.vue";
  import ConfigOptionEmployeeUsedoublebiz from "@/components/config/option/employee/Usedoublebiz.vue";
  import ConfigOptionEmployeeIndividualunit from "@/components/config/option/employee/Individualunit.vue";
  import ConfigOptionEmployeeIsdayover from "@/components/config/option/employee/IsDayOver.vue";
  import ConfigOptionEmployeeIsweekover from "@/components/config/option/employee/IsWeekOver.vue";
  import ConfigOptionEmployeeIsmonthover from "@/components/config/option/employee/IsMonthOver.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LContent,
      LBread,
      ConfigOptionEmployeeNickname,
      ConfigOptionEmployeeAuthgensentax,
      ConfigOptionEmployeeIsbank2,
      ConfigOptionEmployeeBranchhourlywage,
      ConfigOptionEmployeeBranchcarfare,
      ConfigOptionEmployeeIsincometaxopt,
      ConfigOptionEmployeeUsedoublebiz,
      ConfigOptionEmployeeIndividualunit,
      ConfigOptionEmployeeIsdayover,
      ConfigOptionEmployeeIsweekover,
      ConfigOptionEmployeeIsmonthover,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() company: any;
    @Prop() global_config: any;

    get raw_object() {
      return this.company;
    }
    get tmp_object() {
      return this.manager.object.company;
    }

    created() {
      if (this.global_config.editable_company) {
        this.manager.edit({ company: this.raw_object });
      }
    }

    get is_editted() {
      this.manager.setChangeFlag(
        this.raw_object.is_nickname != this.object.is_nickname ||
          this.raw_object.auth_gensentax_employee != this.object.auth_gensentax_employee ||
          this.raw_object.is_bank2 != this.object.is_bank2 ||
          this.raw_object.is_branch_hourly_wage != this.object.is_branch_hourly_wage ||
          this.raw_object.is_branchly_carfare != this.object.is_branchly_carfare ||
          this.raw_object.is_individual_unit != this.object.is_individual_unit ||
          this.raw_object.is_income_tax_opt != this.object.is_income_tax_opt ||
          this.raw_object.is_use_double_biz != this.object.is_use_double_biz ||
          this.raw_object.is_day_over != this.object.is_day_over ||
          this.raw_object.is_week_over != this.object.is_week_over ||
          this.raw_object.is_month_over != this.object.is_month_over,
      );
      return this.manager.change_flag;
    }
  }
