
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigSystemOrderOvertime from "@/components/config/system/order/Overtime.vue";
import ConfigSystemOrderHoliday from "@/components/config/system/order/Holiday.vue";
import ConfigSystemOrderChange from "@/components/config/system/order/Change.vue";
import ConfigSystemOrderPunch from "@/components/config/system/order/Punch.vue";
import ConfigSystemOrderJoin from "@/components/config/system/order/Join.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigSystemOrderOvertime,
    ConfigSystemOrderHoliday,
    ConfigSystemOrderChange,
    ConfigSystemOrderPunch,
    ConfigSystemOrderJoin,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.object});
    }
  }

  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.order_overtime != this.object.order_overtime ||
      this.raw_object.order_overtime_admit != this.object.order_overtime_admit ||
      this.raw_object.order_overtime_mail != this.object.order_overtime_mail ||
      this.raw_object.is_prev_overdemand != this.object.is_prev_overdemand ||

      this.raw_object.order_holiday != this.object.order_holiday ||
      this.raw_object.order_holiday_admit != this.object.order_holiday_admit ||
      this.raw_object.order_holiday_mail != this.object.order_holiday_mail ||

      this.raw_object.order_change != this.object.order_change ||
      this.raw_object.order_change_admit != this.object.order_change_admit ||
      this.raw_object.order_change_mail != this.object.order_change_mail ||

      this.raw_object.order_punch != this.object.order_punch ||
      this.raw_object.order_punch_admit != this.object.order_punch_admit ||
      this.raw_object.order_punch_mail != this.object.order_punch_mail ||

      this.raw_object.join_admit != this.object.join_admit
    );
    return this.manager.change_flag;
  }
  
}
