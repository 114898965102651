
import { Component, Vue } from 'vue-property-decorator';
import ConfigMain from "@/components/config/Main.vue";


@Component({
  components: {
    ConfigMain,
  }
})
export default class Config extends Vue {
  
}
