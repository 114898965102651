
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigBranchauthBranchauthShift from "@/components/config/branchauth/branchauth/Shift.vue";
import ConfigBranchauthBranchauthAttendance from "@/components/config/branchauth/branchauth/Attendance.vue";
import ConfigBranchauthBranchauthPayment from "@/components/config/branchauth/branchauth/Payment.vue";
import ConfigBranchauthBranchauthBonus from "@/components/config/branchauth/branchauth/Bonus.vue";
import ConfigBranchauthBranchauthNencho from "@/components/config/branchauth/branchauth/Nencho.vue";
import ConfigBranchauthBranchauthDocument from "@/components/config/branchauth/branchauth/Document.vue";
import ConfigBranchauthBranchauthNotification from "@/components/config/branchauth/branchauth/Notification.vue";
import ConfigBranchauthBranchauthEmployee from "@/components/config/branchauth/branchauth/Employee.vue";
import ConfigBranchauthBranchauthConfig from "@/components/config/branchauth/branchauth/Config.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigBranchauthBranchauthShift,
    ConfigBranchauthBranchauthAttendance,
    ConfigBranchauthBranchauthPayment,
    ConfigBranchauthBranchauthBonus,
    ConfigBranchauthBranchauthNencho,
    ConfigBranchauthBranchauthDocument,
    ConfigBranchauthBranchauthNotification,
    ConfigBranchauthBranchauthEmployee,
    ConfigBranchauthBranchauthConfig,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    this.manager.edit({company: this.object});
  }

  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.auth_shift != this.object.auth_shift ||
      this.raw_object.auth_summery != this.object.auth_summery ||

      this.raw_object.auth_attendance != this.object.auth_attendance ||
      this.raw_object.auth_attendance_lock != this.object.auth_attendance_lock ||

      this.raw_object.auth_attendance_arbeit != this.object.auth_attendance_arbeit ||
      this.raw_object.auth_attendance_worker != this.object.auth_attendance_worker ||
      this.raw_object.auth_attendance_pres != this.object.auth_attendance_pres ||
      this.raw_object.auth_attendance_other != this.object.auth_attendance_other ||
      this.raw_object.auth_attendance_gps_arbeit != this.object.auth_attendance_gps_arbeit ||
      this.raw_object.auth_attendance_gps_worker != this.object.auth_attendance_gps_worker ||
      this.raw_object.auth_attendance_gps_pres != this.object.auth_attendance_gps_pres ||
      this.raw_object.auth_attendance_gps_other != this.object.auth_attendance_gps_other ||

      this.raw_object.auth_payment != this.object.auth_payment ||
      this.raw_object.auth_payment_lock != this.object.auth_payment_lock ||

      this.raw_object.auth_payment_arbeit != this.object.auth_payment_arbeit ||
      this.raw_object.auth_payment_worker != this.object.auth_payment_worker ||
      this.raw_object.auth_payment_pres != this.object.auth_payment_pres ||
      this.raw_object.auth_payment_other != this.object.auth_payment_other ||

      this.raw_object.auth_bonus != this.object.auth_bonus ||
      this.raw_object.auth_bonus_lock != this.object.auth_bonus_lock ||

      this.raw_object.auth_bonus_arbeit != this.object.auth_bonus_arbeit ||
      this.raw_object.auth_bonus_worker != this.object.auth_bonus_worker ||
      this.raw_object.auth_bonus_pres != this.object.auth_bonus_pres ||
      this.raw_object.auth_bonus_other != this.object.auth_bonus_other ||
      
      this.raw_object.auth_nencho != this.object.auth_nencho ||
      this.raw_object.auth_nencho_reflect != this.object.auth_nencho_reflect ||

      this.raw_object.auth_nencho_arbeit != this.object.auth_nencho_arbeit ||
      this.raw_object.auth_nencho_worker != this.object.auth_nencho_worker ||
      this.raw_object.auth_nencho_pres != this.object.auth_nencho_pres ||
      this.raw_object.auth_nencho_other != this.object.auth_nencho_other ||

      this.raw_object.auth_document != this.object.auth_document ||

      this.raw_object.auth_notification != this.object.auth_notification ||

      this.raw_object.auth_employee_self != this.object.auth_employee_self ||
      this.raw_object.auth_employee_company != this.object.auth_employee_company ||
      this.raw_object.auth_employee_paidhol != this.object.auth_employee_paidhol ||

      this.raw_object.auth_config_company != this.object.auth_config_company ||
      this.raw_object.auth_config_group != this.object.auth_config_group
    );
    return this.manager.change_flag;
  }
}
