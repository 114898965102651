
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import FText from "@/components/f/Text.vue";
import FSelect from "@/components/f/Select.vue";
import FButton from "@/components/f/Button.vue";
import FControlEditdelete from "@/components/f/ControlEditdelete.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    FText,
    FSelect,
    FButton,
    FControlEditdelete,
    FControl,
  }
})
export default class Row extends Mixins(mixinPage) {
  @Prop() paymentgroup;
  @Prop() paymentgroup_employees;
  @Prop() branch_hash;
  @Prop() global_config;

  get raw_object() {
    return this.paymentgroup;
  }
  get tmp_object() {
    return this.manager.object.paymentgroup;
  }

  get belong_num() {
    return this.paymentgroup_employees[this.object.id] ? this.paymentgroup_employees[this.object.id].length : 0;
  }

  @Provide('is_edit')
  get is_edit() {
    return this.raw_object && this.manager.flag == this.raw_object.id;
  }
  @Provide('is_add')
  get is_add() {
    return !this.raw_object && !!this.manager.flag;
  }
}
