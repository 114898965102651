
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FToggle from "@/components/f/Toggle.vue";
  import dialog from "@/vuex/dialog";

  @Component({
    components: {
      LItem,
      FToggle,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "shift_punch_sync";
      this._info = "info.shift_punch_sync";
      this._popup_title = "shift_punch_sync";
      this._popup_content = "popup.shift_punch_sync";
    }

    changeIsShiftPunchSync(val) {
      if (!val) return;
      dialog.openAlertDialog({
        title: "注意",
        msg: "打刻からシフト作成を「使用する」に設定すると、以下ができなくなります",
        detail:
          "・シフトの編集 : 打刻から強制的にシフトが上書きされるため、シフトの手動編集が一切できなくなります。<br>・丸め機能 : 丸めはシフトの外側でのみ機能するため、丸めが効かなくなります。<br>・遅刻や早退の計算 : 遅刻や早退は一切計算できなくなります。<br>・残業申請 : 残業申請はシフトを超えている部分に適用されるため、残業申請は使用できません(シフト外出勤申請は可能です)。<br><br>以上ができなくなると問題がある場合、この機能を使用せずにシフトを作成してください。<br>シフトテーブルページやシフト一括登録など、シフトを登録する機能を使用することをお勧めします。",
      });
    }
  }
