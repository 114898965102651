
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LTable from "@/components/l/Table.vue";
import LTableHead from "@/components/l/Table/Head.vue";
import LBread from "@/components/l/Bread.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FInfo from "@/components/f/Info.vue";
import FControl from "@/components/f/Control.vue";
import ConfigPaymentAllowancePaymentotherallowanceRow from "@/components/config/payment/allowance/paymentotherallowance/Row.vue";
import ConfigPaymentAllowancePaymentotherallowanceInitrow from "@/components/config/payment/allowance/paymentotherallowance/Initrow.vue";

@Component({
  components: {
    LTable,
    LTableHead,
    LBread,
    LTableBody,
    FInfo,
    FControl,
    ConfigPaymentAllowancePaymentotherallowanceRow,
    ConfigPaymentAllowancePaymentotherallowanceInitrow,
  }
})
export default class Main extends Mixins(mixinConfig) {
  get add_payment_other_allowance_object() {
    return {
      name: null,
      init_type: 1,
      unit: 0,
      num_type: 0,
      order_num: null,
      paymentgroup_id: 0,
      per: 0,
      unitstr: this.$t('times_number'),
      minus: 0,
      hiring_type: null,
      individual_unit_id: null,
    };
  }

  get row_num() {
    let row_num = this.allowance.payment_other_allowances.length + 2;
    row_num += 2;
    return row_num;
  }
}
