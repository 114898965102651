
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTable from "@/components/l/Table.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FControl from "@/components/f/Control.vue";
import ConfigPaymentGroupRow from "@/components/config/payment/group/Row.vue";
import LTableFoot from "@/components/l/Table/Foot.vue";
import ConfigPaymentGroupMember from "@/components/config/payment/group/Member.vue";

@Component({
  components: {
    LProgress,
    LContent,
    LBread,
    LTable,
    LTableBody,
    FControl,
    ConfigPaymentGroupRow,
    LTableFoot,
    ConfigPaymentGroupMember,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initAddManager({name: 'paymentgroup'});
    this.m.initEditManager({name: 'paymentgroup'});
    this.m.setDialogFlag({name: 'paymentgroup_member', val: false});
    this.m.setTmpObjects({name: 'paymentgroup', val: null});
    this.m.setTmpObjects({name: 'belong_paymentgroup', val: null});
  }

  get add_paymentgroup_object() {
    return {
      name: null,
      branch_id: 1,
    };
  }

  belong_num(paymentgroup_id) {
    return this.group.paymentgroup_employees[paymentgroup_id] ? this.group.paymentgroup_employees[paymentgroup_id].length : 0;
  }
}
