
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigPaymentPayCloseday from "@/components/config/payment/pay/Closeday.vue";
import ConfigPaymentPayPaymonth from "@/components/config/payment/pay/Paymonth.vue";
import ConfigPaymentPayPayday from "@/components/config/payment/pay/Payday.vue";
import ConfigPaymentPayLevymonth from "@/components/config/payment/pay/Levymonth.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigPaymentPayCloseday,
    ConfigPaymentPayPaymonth,
    ConfigPaymentPayPayday,
    ConfigPaymentPayLevymonth,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;
  
  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }
  
  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.close_day != this.object.close_day ||
      this.raw_object.pay_month != this.object.pay_month ||
      this.raw_object.pay_day != this.object.pay_day ||
      this.raw_object.levy_month != this.object.levy_month
    );
    return this.manager.change_flag;
  }
}
