
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigPaymentAllowancePaymentallowanceMain from "@/components/config/payment/allowance/paymentallowance/Main.vue";
import ConfigPaymentAllowancePaymentdeductionMain from "@/components/config/payment/allowance/paymentdeduction/Main.vue";
import ConfigPaymentAllowancePaymentotherallowanceMain from "@/components/config/payment/allowance/paymentotherallowance/Main.vue";
import ConfigPaymentAllowanceBonusallowanceMain from "@/components/config/payment/allowance/bonusallowance/Main.vue";
import ConfigPaymentAllowanceBonusdeductionMain from "@/components/config/payment/allowance/bonusdeduction/Main.vue";
import ConfigPaymentAllowanceBonusotherallowanceMain from "@/components/config/payment/allowance/bonusotherallowance/Main.vue";

@Component({
  components: {
    LProgress,
    LContent,
    LBread,
    ConfigPaymentAllowancePaymentallowanceMain,
    ConfigPaymentAllowancePaymentdeductionMain,
    ConfigPaymentAllowancePaymentotherallowanceMain,
    ConfigPaymentAllowanceBonusallowanceMain,
    ConfigPaymentAllowanceBonusdeductionMain,
    ConfigPaymentAllowanceBonusotherallowanceMain,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initAddManager({name: 'payment_allowance'});
    this.m.initEditManager({name: 'payment_allowance'});
    this.m.initAddManager({name: 'payment_deduction'});
    this.m.initEditManager({name: 'payment_deduction'});
    this.m.initAddManager({name: 'payment_other_allowance'});
    this.m.initEditManager({name: 'payment_other_allowance'});
    this.m.initAddManager({name: 'bonus_allowance'});
    this.m.initEditManager({name: 'bonus_allowance'});
    this.m.initAddManager({name: 'bonus_deduction'});
    this.m.initEditManager({name: 'bonus_deduction'});
    this.m.initAddManager({name: 'bonus_other_allowance'});
    this.m.initEditManager({name: 'bonus_other_allowance'});
  }
}
