
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tooltip } from "ant-design-vue";

@Component({
  components: {
    Tooltip,
  }
})
export default class Tab extends Vue {
  @Prop() content: string;
  @Prop() link?: string;

  infoindex = 1000;

  created() {
    if (Vue.prototype.$infoindex === undefined) {
      Vue.prototype.$infoindex = 1000;
    }
  }

  onHover() {
    Vue.prototype.$infoindex++;
    this.infoindex = Vue.prototype.$infoindex;
  }
}
