
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import ConfigBranchauthBranchauth from "@/components/config/branchauth/Branchauth.vue";

@Component({
  components: {
    LProgress,
    ConfigBranchauthBranchauth,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initEditManager({name: 'branch_auth'});
  }
}
