
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import ConfigOptionEmployee from "@/components/config/option/Employee.vue";

@Component({
  components: {
    LProgress,
    ConfigOptionEmployee,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initEditManager({name: 'option_employee', val: false});
  }
}
