
  import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
  import mixinEmployeeCheck from "@/mixins/almighty/table/mixinEmployeeCheck";
  import * as util from "@/util";
  import LProgress from "@/components/l/Progress.vue";
  import LTable from "@/components/l/Table.vue";
  import LTableHead from "@/components/l/Table/Head.vue";
  import LTableBody from "@/components/l/Table/Body.vue";
  import AtomicMoleculesCheck from "@/components/atomic/molecules/Check.vue";
  import LTableFoot from "@/components/l/Table/Foot.vue";

  @Component({
    components: {
      LProgress,
      LTable,
      LTableHead,
      LTableBody,
      AtomicMoleculesCheck,
      LTableFoot,
    },
  })
  export default class EmployeeTable extends Mixins(mixinEmployeeCheck) {
    @Prop() value: number[];
    @Prop() only_branch_id?: number; //店舗が決まっている場合にここにセット　シフトグループのメンバー選択など

    created() {
      if (this.branch_id === undefined) {
        let branch_id = Number(util.getLocalStorage("branch_id"));
        if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch && !this.only_branch_id) {
          branch_id = 0;
        }
        this.branch_id = branch_id;
      }
      this.setBranchId();
    }

    @Watch("only_branch_id")
    onChangeBranchId() {
      this.setBranchId();
    }

    setBranchId() {
      if (this.only_branch_id !== undefined) {
        this.branch_id = this.only_branch_id;
      }
    }

    get tmp_selected_employee() {
      return this.value;
    }
    set tmp_selected_employee(val) {
      this.$emit("input", val);
    }

    get use_branch_hash() {
      if (this.only_branch_id && this.only_branch_id !== 0) {
        return {
          [this.only_branch_id]: this.branch_now.branch_hash[this.only_branch_id],
        };
      } else {
        return this.branch_now.branch_hash;
      }
    }

    setSelectedEmployee(val) {
      const index = this.tmp_selected_employee.indexOf(val);
      if (index === -1) {
        this.tmp_selected_employee.push(val);
      } else {
        this.tmp_selected_employee.splice(index, 1);
      }
    }
    resetSelectedEmployee() {
      this.tmp_selected_employee = [];
    }
    setSelectedEmployees(vals) {
      this.tmp_selected_employee = vals;
    }
    checkAllEmployees(val) {
      if (val) {
        const employees = this.employee_now.employees.map((employee) => {
          return employee.id;
        });
        this.setSelectedEmployees(employees);
      } else {
        this.resetSelectedEmployee();
      }
    }

    get select_employee_all() {
      return this.employee_now.employees.length == this.tmp_selected_employee.length;
    }
  }
