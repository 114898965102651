import { render, staticRenderFns } from "./Pay.vue?vue&type=template&id=c5502e32&scoped=true&lang=pug"
import script from "./Pay.vue?vue&type=script&lang=ts"
export * from "./Pay.vue?vue&type=script&lang=ts"
import style0 from "./Pay.vue?vue&type=style&index=0&id=c5502e32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5502e32",
  null
  
)

export default component.exports