
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FSelect from "@/components/f/Select.vue";
  import FCheck from "@/components/f/Check.vue";

  @Component({
    components: {
      LItem,
      FSelect,
      FCheck,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "attendance_error_alert";
      this._info = "info.attendance_error_alert";
    }
    get alert_object() {
      return {
        alert_employee: this.object.alert_attendance_error & 0b1 ? 1 : 0,
        alert_branch: this.object.alert_attendance_error & 0b10 ? 1 : 0,
        alert_company: this.object.alert_attendance_error & 0b100 ? 1 : 0,
      };
    }
    updateAlertObject({ key, val }) {
      let flag = 0;
      if (key == 0) {
        flag = 0b1;
      } else if (key == 1) {
        flag = 0b10;
      } else if (key == 2) {
        flag = 0b100;
      }
      if (val) this.object.alert_attendance_error = this.object.alert_attendance_error | flag;
      else this.object.alert_attendance_error = this.object.alert_attendance_error & ~flag;
    }
  }
