
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigAttendanceHolidayOuthol from "@/components/config/attendance/holiday/Outhol.vue";
import ConfigAttendanceHolidayLawhol from "@/components/config/attendance/holiday/Lawhol.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigAttendanceHolidayOuthol,
    ConfigAttendanceHolidayLawhol,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.raw_object});
    }
  }
  
  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.outhol_sunday != this.object.outhol_sunday ||
      this.raw_object.outhol_monday != this.object.outhol_monday ||
      this.raw_object.outhol_tuesday != this.object.outhol_tuesday ||
      this.raw_object.outhol_wednesday != this.object.outhol_wednesday ||
      this.raw_object.outhol_thursday != this.object.outhol_thursday ||
      this.raw_object.outhol_friday != this.object.outhol_friday ||
      this.raw_object.outhol_saturday != this.object.outhol_saturday ||
      this.raw_object.outhol_holiday != this.object.outhol_holiday ||
      this.raw_object.lawhol_day != this.object.lawhol_day
    );
    return this.manager.change_flag;
  }
}
