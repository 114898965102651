
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import ConfigShiftDispSummerytype from "@/components/config/shift/disp/Summerytype.vue";
  import ConfigShiftDispShowothershift from "@/components/config/shift/disp/Showothershift.vue";
  import ConfigShiftDispShiftpunchsync from "@/components/config/shift/disp/Shiftpunchsync.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LContent,
      LBread,
      ConfigShiftDispSummerytype,
      ConfigShiftDispShowothershift,
      ConfigShiftDispShiftpunchsync,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() company: any;
    @Prop() global_config: any;

    get raw_object() {
      return this.company;
    }
    get tmp_object() {
      return this.manager.object.company;
    }

    created() {
      if (this.global_config.editable_company) {
        this.manager.edit({ company: this.raw_object });
      }
    }

    get is_editted() {
      this.manager.setChangeFlag(
        this.raw_object.summery_type != this.object.summery_type ||
          this.raw_object.show_other_shift != this.object.show_other_shift ||
          this.raw_object.is_shift_punch_sync != this.object.is_shift_punch_sync,
      );
      return this.manager.change_flag;
    }
  }
