
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import ConfigPaymentOtherIsbranchbank from "@/components/config/payment/other/Isbranchbank.vue";
import FControl from "@/components/f/Control.vue";

@Component({
  components: {
    LContent,
    LBread,
    ConfigPaymentOtherIsbranchbank,
    FControl,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() company: any;
  @Prop() global_config: any;

  get raw_object() {
    return this.company;
  }
  get tmp_object() {
    return this.manager.object.company;
  }

  created() {
    if (this.global_config.editable_company) {
      this.manager.edit({company: this.raw_object});
    }
  }

  get is_editted() {
    this.manager.setChangeFlag(
      this.raw_object.is_branch_bank != this.object.is_branch_bank
    );
    return this.manager.change_flag;
  }
}
