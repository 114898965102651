
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FRadio from "@/components/f/Radio.vue";

  @Component({
    components: {
      LItem,
      FRadio,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "show_other_shift";
      this._info = "info.show_other_shift";
      this._popup_title = "show_other_shift";
      this._popup_content = "popup.show_other_shift";
    }
  }
