
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import ConfigPaymentPay from "@/components/config/payment/Pay.vue";
import ConfigPaymentRate from "@/components/config/payment/Rate.vue";
import ConfigPaymentSlip from "@/components/config/payment/Slip.vue";
import ConfigPaymentAllowanceMain from "@/components/config/payment/allowance/Main.vue";
import ConfigPaymentOther from "@/components/config/payment/Other.vue";
import ConfigPaymentGroup from "@/components/config/payment/Group.vue";

@Component({
  components: {
    LProgress,
    ConfigPaymentPay,
    ConfigPaymentRate,
    ConfigPaymentSlip,
    ConfigPaymentAllowanceMain,
    ConfigPaymentOther,
    ConfigPaymentGroup,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initEditManager({name: 'payment_pay'});
    this.m.initEditManager({name: 'payment_rate'});
    this.m.initEditManager({name: 'payment_slip'});
    this.m.initEditManager({name: 'payment_other'});
  }
}
