
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import ConfigSystemAlertAlert from "@/components/config/system/alert/Alert.vue";
  import ConfigSystemAlertNeed from "@/components/config/system/alert/Need.vue";
  import ConfigSystemAlertLoginappnotification from "@/components/config/system/alert/Loginappnotification.vue";
  import ConfigSystemAlertAlertattendanceerror from "@/components/config/system/alert/Alertattendanceerror.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LContent,
      LBread,
      ConfigSystemAlertAlert,
      ConfigSystemAlertNeed,
      ConfigSystemAlertLoginappnotification,
      ConfigSystemAlertAlertattendanceerror,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() company: any;
    @Prop() global_config: any;

    get raw_object() {
      return this.company;
    }
    get tmp_object() {
      return this.manager.object.company;
    }

    created() {
      if (this.global_config.editable_company) {
        this.manager.edit({ company: this.raw_object });
      }
    }

    get is_editted() {
      this.manager.setChangeFlag(
        this.raw_object.alert_unit != this.object.alert_unit ||
          (this.object.alert_unit != 0 &&
            (this.raw_object.alert_employee != this.object.alert_employee ||
              this.raw_object.alert_branch != this.object.alert_branch ||
              this.raw_object.alert_company != this.object.alert_company)) ||
          this.raw_object.need_mail_arbeit != this.object.need_mail_arbeit ||
          this.raw_object.need_mail_worker != this.object.need_mail_worker ||
          this.raw_object.need_mail_pres != this.object.need_mail_pres ||
          this.raw_object.need_mail_other != this.object.need_mail_other ||
          this.raw_object.allow_app_no_notification != this.object.allow_app_no_notification ||
          this.raw_object.alert_attendance_error != this.object.alert_attendance_error,
      );
      return this.manager.change_flag;
    }

    get alert_mail_company() {
      const result = [];
      if (this.object.alert_employee) result.push("本人");
      if (this.object.alert_branch) result.push("店舗管理者");
      if (this.object.alert_company) result.push("会社管理者");
      return result.length == 0 ? "なし" : result.join("/");
    }
    get need_mail_company() {
      const result = [];
      if (this.object.need_mail_arbeit) result.push("パート・アルバイト");
      if (this.object.need_mail_worker) result.push("社員");
      if (this.object.need_mail_pres) result.push("役員");
      if (this.object.need_mail_other) result.push("その他");
      return result.length == 0 ? "なし" : result.join("/");
    }
  }
