
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FToggle from "@/components/f/Toggle.vue";

@Component({
  components: {
    LItem,
    FToggle,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'use_nickname';
    this._info = 'info.use_nickname';
    this._popup_title = 'use_nickname';
    this._popup_content = 'popup.use_nickname';
  }
}
