
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FCheck from "@/components/f/Check.vue";

@Component({
  components: {
    LItem,
    FCheck,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'need_alert';
    this._info = 'info.need_alert';
    this._popup_title = 'need_alert';
    this._popup_content = 'popup.need_alert';
  }
}
