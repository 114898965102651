
import mixinPart from '@/mixins/mixinPart';
import { Component, Mixins } from 'vue-property-decorator';
import LItem from "@/components/l/Item.vue";
import FSelect from "@/components/f/Select.vue";

@Component({
  components: {
    LItem,
    FSelect,
  }
})
export default class Main extends Mixins(mixinPart) {
  created() {
    this._title = 'document';
  }
}
