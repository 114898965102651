
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import LContent from "@/components/l/Content.vue";
  import LBread from "@/components/l/Bread.vue";
  import ConfigAttendanceOtherAddpaidholtime from "@/components/config/attendance/other/Addpaidholtime.vue";
  import ConfigAttendanceOtherShowattendancechart from "@/components/config/attendance/other/Showattendancechart.vue";
  import ConfigAttendanceOtherIsdeempreover from "@/components/config/attendance/other/Isdeempreover.vue";
  import ConfigAttendanceOtherNoshifterror from "@/components/config/attendance/other/Noshifterror.vue";
  import ConfigAttendanceOtherBeforejoin from "@/components/config/attendance/other/Beforejoin.vue";
  import ConfigAttendanceOtherCalcpaidhol from "@/components/config/attendance/other/Calcpaidhol.vue";
  import ConfigAttendanceOtherContinuoustime from "@/components/config/attendance/other/Continuoustime.vue";
  import ConfigAttendanceOtherContinuousday from "@/components/config/attendance/other/Continuousday.vue";
  import FControl from "@/components/f/Control.vue";

  @Component({
    components: {
      LContent,
      LBread,
      ConfigAttendanceOtherAddpaidholtime,
      ConfigAttendanceOtherShowattendancechart,
      ConfigAttendanceOtherIsdeempreover,
      ConfigAttendanceOtherNoshifterror,
      ConfigAttendanceOtherBeforejoin,
      ConfigAttendanceOtherCalcpaidhol,
      ConfigAttendanceOtherContinuoustime,
      ConfigAttendanceOtherContinuousday,
      FControl,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    @Prop() company: any;
    @Prop() global_config: any;

    get raw_object() {
      return this.company;
    }
    get tmp_object() {
      return this.manager.object.company;
    }

    created() {
      if (this.global_config.editable_company) {
        this.manager.edit({ company: this.raw_object });
      }
    }

    get is_editted() {
      this.manager.setChangeFlag(
        this.raw_object.add_paidhol_time != this.object.add_paidhol_time ||
          this.raw_object.show_attendance_chart != this.object.show_attendance_chart ||
          this.raw_object.is_deem_preover != this.object.is_deem_preover ||
          this.raw_object.is_use_noshift_error != this.object.is_use_noshift_error ||
          this.raw_object.attendance_after_join != this.object.attendance_after_join ||
          this.raw_object.calc_paidhol != this.object.calc_paidhol ||
          this.raw_object.is_continuous_error_time != this.object.is_continuous_error_time ||
          this.raw_object.is_continuous_error_day != this.object.is_continuous_error_day ||
          this.raw_object.continuous_error_time != this.object.continuous_error_time ||
          this.raw_object.continuous_error_day != this.object.continuous_error_day,
      );
      return this.manager.change_flag;
    }
  }
