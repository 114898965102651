
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FSelect from "@/components/f/Select.vue";
  import FCheck from "@/components/f/Check.vue";

  @Component({
    components: {
      LItem,
      FSelect,
      FCheck,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "delay_alert";
      this._info = "info.delay_alert";
      this._popup_title = "delay_alert";
      this._popup_content = "popup.delay_alert";
    }
  }
