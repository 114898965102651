
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class Check extends Vue {
  @Prop() value: any;
  @Prop({default: false}) multi: boolean | number[];
  @Prop({default: 'null'}) this_value: any;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: 'normal'}) background_color: string;
  @Prop({default: 'normal'}) border_color: string;
  @Prop({default: false}) is_option_vertical: boolean;
  @Prop({default: false}) is_check_all: boolean;
  
  get checked_value() {
    if (this.multi) {
      if (typeof this.multi === 'boolean') {
        return this.value.indexOf(Number(this.this_value)) !== -1;
      } else if (this.is_check_all) {
        return this.multi.length === this.value.length;
      }
    } else {
      return this.value;
    }
  }
  set checked_value(val) {
    this.change_checked_value(val ? 1 : 0);
  }

  change_checked_value(val) {
    if (this.multi) {
      if (typeof this.multi === 'boolean') {
        if (val) {
          var tmp = this.value;
          tmp.push(this.this_value);
          this.$emit('input', tmp);
        } else {
          this.$emit('input', this.value.filter(value => value != this.this_value));
        }
      } else {
        console.log(this.multi, this.value);
        if (this.multi.length === this.value.length) {
          this.$emit('input', []);
        } else {
          this.$emit('input', this.multi);
        }
      }
    } else {
      this.$emit('input', val);
    }
  }
}
