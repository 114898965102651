
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FToggle from "@/components/f/Toggle.vue";

  @Component({
    components: {
      LItem,
      FToggle,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "calc_paidhol";
    }
  }
