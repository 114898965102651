
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTable from "@/components/l/Table.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FControl from "@/components/f/Control.vue";
import ConfigShiftGroupRow from "@/components/config/shift/group/Row.vue";
import LTableFoot from "@/components/l/Table/Foot.vue";
import ConfigShiftGroupMember from "@/components/config/shift/group/Member.vue";

@Component({
  components: {
    LProgress,
    LContent,
    LBread,
    LTable,
    LTableBody,
    FControl,
    ConfigShiftGroupRow,
    LTableFoot,
    ConfigShiftGroupMember,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initAddManager({name: 'shiftgroup'});
    this.m.initEditManager({name: 'shiftgroup'});
    this.m.setDialogFlag({name: 'shiftgroup_member', val: false});
    this.m.setTmpObjects({name: 'shiftgroup', val: null});
    this.m.setTmpObjects({name: 'belong_shiftgroup', val: null});
  }

  get add_shiftgroup_object() {
    return {
      name: null,
      branch_id: 1,
    };
  }

  belong_num(shiftgroup_id) {
    return this.group.shiftgroup_employees[shiftgroup_id] ? this.group.shiftgroup_employees[shiftgroup_id].length : 0;
  }
}
