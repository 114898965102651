
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";

  @Component({
    components: {},
  })
  export default class Row extends Mixins(mixinPage) {
    @Prop() name: string;
    @Prop() group_name: string;
    @Prop() is_tax: number;
    @Prop() is_social_ins: number;
    @Prop() is_labor_ins: number;
    @Prop() is_wage_calc: number;
    @Prop() global_config: any;
  }
