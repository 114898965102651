
  import { Component, Prop, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import { Provide } from "@/library/provide";
  import FText from "@/components/f/Text.vue";
  import FSelect from "@/components/f/Select.vue";
  import FControlEditdelete from "@/components/f/ControlEditdelete.vue";

  @Component({
    components: {
      FText,
      FSelect,
      FControlEditdelete,
    },
  })
  export default class Row extends Mixins(mixinPage) {
    @Prop() employee_info;
    @Prop() global_config: any;

    get raw_object() {
      return this.employee_info;
    }
    get tmp_object() {
      return this.manager.object.employee_info;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.raw_object && this.manager.flag == this.raw_object.id;
    }
    @Provide("is_add")
    get is_add() {
      return !this.raw_object && !!this.manager.flag;
    }

    get is_option_type() {
      return this.object.type == 3;
    }

    get rowspan() {
      return this.is_option_type ? 2 : 1;
    }
  }
