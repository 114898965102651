
  import { Component, Mixins } from "vue-property-decorator";
  import mixinConfig from "@/mixins/mixinConfig";
  import LTable from "@/components/l/Table.vue";
  import LTableHead from "@/components/l/Table/Head.vue";
  import LBread from "@/components/l/Bread.vue";
  import LTableBody from "@/components/l/Table/Body.vue";
  import FInfo from "@/components/f/Info.vue";
  import FTip from "@/components/f/Tip.vue";
  import FControl from "@/components/f/Control.vue";
  import ConfigPaymentAllowancePaymentdeductionRow from "@/components/config/payment/allowance/paymentdeduction/Row.vue";
  import ConfigPaymentAllowancePaymentdeductionInitrow from "@/components/config/payment/allowance/paymentdeduction/Initrow.vue";

  @Component({
    components: {
      LTable,
      LTableHead,
      LBread,
      LTableBody,
      FInfo,
      FTip,
      FControl,
      ConfigPaymentAllowancePaymentdeductionRow,
      ConfigPaymentAllowancePaymentdeductionInitrow,
    },
  })
  export default class Main extends Mixins(mixinConfig) {
    get add_payment_deduction_object() {
      return {
        name: null,
        init_type: 1,
        unit: 0,
        num_type: 0,
        is_tax: 1,
        is_mutualaid: 0,
        order_num: null,
        paymentgroup_id: 0,
        per: 0,
        unitstr: this.$t("times_number"),
        minus: 0,
        hiring_type: null,
        individual_unit_id: null,
      };
    }

    get row_num() {
      let row_num = this.allowance.payment_deductions.length + 2;
      row_num += 7;
      return row_num;
    }
  }
