
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import LTable from "@/components/l/Table.vue";
import LTableBody from "@/components/l/Table/Body.vue";
import FControl from "@/components/f/Control.vue";
import ConfigSystemInfoRow from "@/components/config/system/info/Row.vue";
import LTableFoot from "@/components/l/Table/Foot.vue";

@Component({
  components: {
    LProgress,
    LContent,
    LBread,
    LTable,
    LTableBody,
    FControl,
    ConfigSystemInfoRow,
    LTableFoot,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initAddManager({name: 'employee_info'});
    this.m.initEditManager({name: 'employee_info'});
  }

  get add_employee_info_object() {
    return {
      name: null,
      type: null,
      is_join: 0,
      is_employee: 0,
      is_branch: 0,
      option1: null,
      option2: null,
    };
  }

  get rownum() {
    var row_num = 0;
    this.employeeinfo_table.managed_data.forEach(employee_info => {
      if (employee_info.type == 3 && this.m.edit_manager.employee_info.flag !== employee_info.id) row_num++;
    });
    
    return row_num
      + (this.m.add_manager.employee_info.flag && this.m.add_manager.employee_info.object.employee_info.type == 3 ? 1 : 0)
      + (this.m.edit_manager.employee_info.flag && this.m.edit_manager.employee_info.object.employee_info.type == 3 ? 1 : 0);
  }
}
