
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import ConfigSystemInfo from "@/components/config/system/Info.vue";
import ConfigSystemAlert from "@/components/config/system/Alert.vue";
import ConfigSystemOrder from "@/components/config/system/Order.vue";

@Component({
  components: {
    LProgress,
    ConfigSystemInfo,
    ConfigSystemAlert,
    ConfigSystemOrder,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initEditManager({name: 'system_alert'});
    this.m.initEditManager({name: 'system_order'});
  }
}
