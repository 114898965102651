
  import { Component, Mixins } from "vue-property-decorator";
  import { Alert } from "ant-design-vue";
  import mixinPart from "@/mixins/mixinPart";
  import LItem from "@/components/l/Item.vue";
  import FToggle from "@/components/f/Toggle.vue";
  import FSelect from "@/components/f/Select.vue";

  @Component({
    components: {
      LItem,
      FToggle,
      FSelect,
      AAlert: Alert,
    },
  })
  export default class Main extends Mixins(mixinPart) {
    created() {
      this._title = "order_overtime";
      this._info = "info.order_overtime";
      this._popup_title = "order_overtime";
      this._popup_content = "popup.order_overtime";
    }
  }
