
import { Component, Prop, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import AtomicAtomsCheck from "@/components/atomic/atoms/Check.vue";

@Component({
  components: {
    AtomicAtomsCheck,
  }
})
export default class Normal extends Mixins(utilMixins) {
  @Prop() value: number | string;
  @Prop({default: false}) multi: boolean | number[];
  @Prop() options?: {[id: number | string]: number | string};
  @Prop() order?: number[];
  @Prop() disabled: boolean;
  @Prop({default: false}) is_vertical: boolean;
  @Prop({default: false}) is_option_vertical: boolean;
  @Prop({default: false}) is_check_all: boolean;

  get tmp_value() {
    return this.value;
  }
  set tmp_value(val) {
    this.$emit('input', val);
  }
  
  get use_options() {
    var options = [];
    if (this.order) {
      this.order.forEach(key => {
        options.push({key: key, value: this.options[key]});
      });
    } else {
      Object.keys(this.options).forEach(key => {
        options.push({key: key, value: this.options[key]});
      });
    }
    return options;
  }
}
