import { render, staticRenderFns } from "./Alert.vue?vue&type=template&id=b52cc86e&scoped=true&lang=pug"
import script from "./Alert.vue?vue&type=script&lang=ts"
export * from "./Alert.vue?vue&type=script&lang=ts"
import style0 from "./Alert.vue?vue&type=style&index=0&id=b52cc86e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b52cc86e",
  null
  
)

export default component.exports