import { Component, Mixins } from "vue-property-decorator";
import utilMixins from "@/mixins";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";
import vuex_table from "@/vuex/vuex_table";
import * as util from "@/util";

@Component
export default class mixinEmployeeCheck extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.almighty_manager;
  }
  get employee_now() {
    return vuex_data.almighty_employee_now.get(this);
  }
  get employee_sub_now() {
    return vuex_data.almighty_employee_sub_now.get(this);
  }
  get branch_now() {
    return vuex_data.almighty_branch_now.get(this);
  }

  get employee_check_table() {
    return vuex_table.employee_check.get(this);
  }
  get employee_sub_check_table() {
    return vuex_table.employee_sub_check.get(this);
  }

  created() {
    this.m.initState({ name: "branch_id" });
  }

  get branch_id() {
    return this.m.state.branch_id;
  }
  set branch_id(val) {
    this.employee_check_table.page = 1;
    this.employee_sub_check_table.page = 1;
    this.m.state.branch_id = val;
  }
}
