
import { Component, Mixins } from 'vue-property-decorator';
import mixinConfig from '@/mixins/mixinConfig';
import LProgress from "@/components/l/Progress.vue";
import ConfigAttendanceHoliday from "@/components/config/attendance/Holiday.vue";
import ConfigAttendanceRound from "@/components/config/attendance/Round.vue";
import ConfigAttendancePunch from "@/components/config/attendance/Punch.vue";
import ConfigAttendanceOther from "@/components/config/attendance/Other.vue";

@Component({
  components: {
    LProgress,
    ConfigAttendanceHoliday,
    ConfigAttendanceRound,
    ConfigAttendancePunch,
    ConfigAttendanceOther,
  }
})
export default class Main extends Mixins(mixinConfig) {
  created() {
    this.m.initEditManager({name: 'attendance_holiday'});
    this.m.initEditManager({name: 'attendance_round'});
    this.m.initEditManager({name: 'attendance_punch'});
    this.m.initEditManager({name: 'attendance_other'});
  }
}
